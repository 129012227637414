<template>
  <div
    class="w-full h-screen bg-white flex flex-col justify-center items-center text-center"
  >
    <img src="@/assets/404-error.jpg" alt="404 error" />
    <div class="w-2/4 mt-5">
      <h1 class="font-semibold text-lg md:text-4xl">Oops ! Page not found</h1>
      <p class="text-base md:text-lg mt-2 mb-8">
        We looked everywhere for this page. Are you sure the website URL is
        correct?
      </p>
      <router-link
        class="py-3 px-2 bg-mindaro font-bold m-auto"
        :to="{ name: 'Home' }"
        >Go back home</router-link
      >
    </div>
  </div>
</template>

<script></script>

<style></style>
